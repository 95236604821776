import React from "react"
import Button from "../button"
import * as styles from "./styles.module.css"

const GroupSidebar = ({ headline, button }) => {
    return (
        <aside className={`${styles.group_sidebar} col-lg-2`}>
            <h3 className="pb-30">{headline}</h3>
            {button.map((button, index) =>
                <Button
                    key={index}
                    type="jumboPurple"
                    text={button.buttonText}
                    url={button.buttonUrl}
                    newTab={button.newTab}
                />
            )}
        </aside>
    )
}

export default GroupSidebar