import React from "react"
import * as styles from "./styles.module.css"

const GroupRow = ({ data }) => {
    return (
        <div className={styles.group_row}>
            <h3>{data.headline}</h3>
            <div className={styles.tags}>
                <p className={styles.cost}>${data.costPerSession}/session</p>
                <p className={`${data.location === "Virtual" ? styles.virtual : styles.in_person}`}>{data.location}</p>
            </div>
            <div className={styles.info}>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: data.description }}></div>
                <div className={styles.time}>
                    <div className={styles.row}>
                        <p className={styles.title}>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <path d="M4.0293 0.666504V3.33317" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M13.3623 0.666504V3.33317" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1.3623 7.33301H16.029" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.6956 3.33301H2.69564C1.95926 3.33301 1.3623 3.92996 1.3623 4.66634V13.9997C1.3623 14.7361 1.95926 15.333 2.69564 15.333H14.6956C15.432 15.333 16.029 14.7361 16.029 13.9997V4.66634C16.029 3.92996 15.432 3.33301 14.6956 3.33301Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Day
                        </p>
                        <p className={`${styles.info_text} xl-body`}>{data.day}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.title}>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <g clipPath="url(#clip0_1450_7975)">
                                <path d="M9.19531 15.5C13.0613 15.5 16.1953 12.366 16.1953 8.5C16.1953 4.63401 13.0613 1.5 9.19531 1.5C5.32932 1.5 2.19531 4.63401 2.19531 8.5C2.19531 12.366 5.32932 15.5 9.19531 15.5Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.19531 4.5V8.5H13.1953" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_1450_7975">
                                <rect width="16" height="16" fill="white" transform="translate(0.695312)"/>
                                </clipPath>
                                </defs>
                            </svg>
                            Time
                        </p>
                        <p className={`${styles.info_text} xl-body`}>{data.time}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.title}>
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                                <path d="M13.1953 15.5C13.1953 15.5 13.1953 14.716 13.1953 14.5C13.1953 11.657 12.2403 9.11 10.6953 8C12.2403 6.89 13.1953 4.343 13.1953 1.5C13.1953 1.284 13.1953 0.5 13.1953 0.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.19531 15.5C4.19531 15.5 4.19531 14.716 4.19531 14.5C4.19531 11.657 5.15031 9.11 6.69531 8C5.15031 6.89 4.19531 4.343 4.19531 1.5C4.19531 1.284 4.19531 0.5 4.19531 0.5" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M2.19531 0.5H15.1953" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M2.19531 15.5H15.1953" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Duration
                        </p>
                        <p className={`${styles.info_text} xl-body`}>{data.duration}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupRow