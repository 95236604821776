import React from "react"
import GroupRow from "./groupRow"
import * as styles from "./styles.module.css"

const Groups = ({ groups, mobileText, buttonUrl, newTab, buttonText }) => {
    return (
        <div className="col-lg-9 offset-lg-1">
            {groups.map((row, index) =>
                <GroupRow key={index} data={row}/>
            )}
            <a className={styles.mobile_button} href="/group-screening">{mobileText ? mobileText : buttonText}</a>
        </div>
    )
}

export default Groups