// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var colors = "\"../../../assets/css/_colors.css\"";
export var cost = "styles-module--cost--82ebd";
export var description = "styles-module--description--95b09";
export var fonts = "\"../../../assets/css/_fonts.css\"";
export var group_row = "styles-module--group_row--30896";
export var gtWalsheim = "'gt-walsheim'";
export var in_person = "styles-module--in_person--9e59f";
export var info = "styles-module--info--77a33";
export var info_text = "styles-module--info_text--175f7";
export var lightBg = "#FFF8E8";
export var lightestPurple = "#E2DDFF";
export var mediumOrange = "#FFB96F";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var row = "styles-module--row--04fb5";
export var tags = "styles-module--tags--8d69e";
export var time = "styles-module--time--3a7f9";
export var title = "styles-module--title--a6466";
export var virtual = "styles-module--virtual--4c0d4";
export var yellowBtn = "#FFE07B";