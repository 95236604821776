import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import GenericBanner from "../components/genericBanner"
import Cta from "../components/cta"
import GroupSidebar from "../components/groupSidebar"
import Groups from "../components/groups"

export default function GroupOfferingsPage({ data: { page } }) {
  return (
    <Layout>
        {page.banner.map((block, index) =>
            <GenericBanner key={index} banner={block} title={page.title} marginTop={60} marginBottom={120} mobileMarginBottom={60}/>
        )}
        <div className="container-fluid pb-75">
            <div className="row">
                <GroupSidebar headline={page.headline} button={page.button}/>
                <Groups groups={page.groupOfferings} buttonUrl={page.button.buttonUrl} buttonText={page.button.buttonText} newTab={page.button.newTab} mobileText={page.mobileText}/>
            </div>
        </div>
        {page.cta.map((block, index) =>
            <Cta 
                key={index} 
                data={block} 
                type={`${block.model.apiKey === "shapes_cta" ? "shapes" : "default"}`}
            />
        )}
    </Layout>
  )
}

export const query = graphql`
query GroupsQuery {
    page: datoCmsGroupOfferingsPage {
        title
        banner {
        backgroundImage {
            alt 
            data: gatsbyImageData
            url
        }
        mobileBackgroundImage {
            alt 
            data: gatsbyImageData
            url
        }
        }
        headline
        mobileText
        button {
        buttonText
        buttonUrl
        newTab
        }
        groupOfferings {
        headline
        costPerSession
        location
        description
        day
        time
        duration
        }
        seo {
          title
          description
          image {
            url
          }
        }
        cta {
        ... on DatoCmsShapesCta {
            model {
            apiKey
            }
            headline
            body
            shapeType
            buttonText
            buttonUrl
            newTab
        }
        }
    }
}
`

export const Head = ({ data }) => {
    return (
      <Seo 
        title={data.page.seo.title}
        description={data.page.seo.description}
        image={data.page.seo.image}
      />
    )
  }